<template>
  <div class="message-chat" style="height:100dvh">
    <div class="chat-header">
      <img class="BackButton" @click="backToOverview" :src="BackIcon" alt="Back Icon" />
      <div class="NameOfMessage">
        <router-link :to="{ name: 'creatorFeed', params: { id: creatorName }}" class="creator-name">
          {{ selectedConversation.other_user }}
        </router-link>
      </div>
    </div>

    <div class="chat-body">
      <ChatMessages
          v-if="selectedConversation.id"
          class="chat-messages"
          ref="chatMessages"
          :conversationId="selectedConversation.id"
          :userId="userId"
      />
    </div>

    <!-- Media Preview Section -->
    <div v-if="file || audioBlob" class="media-preview">
      <!-- File Preview -->
      <div v-if="isImage(file)" class="media-preview-item">
        <img :src="filePreviewUrl" alt="Image Preview" class="preview-image" />
        <button @click="removeAttachment" class="remove-btn">&times;</button>
      </div>

      <div v-else-if="isVideo(file)" class="media-preview-item">
        <video :src="filePreviewUrl" controls class="preview-video"></video>
        <button @click="removeAttachment" class="remove-btn">&times;</button>
      </div>

      <div v-else-if="isAudio(file)" class="media-preview-item">
        <audio :src="filePreviewUrl" controls class="preview-audio"></audio>
        <button @click="removeAttachment" class="remove-btn">&times;</button>
      </div>

      <!-- Audio Recording Preview -->
      <div v-if="audioBlob" class="media-preview-item">
        <audio :src="audioUrl" controls class="preview-audio"></audio>
        <button @click="removeAudioRecording" class="remove-btn">&times;</button>
      </div>
    </div>
    <div v-if="canSendMessage" class="chat-input">
      <div class="chatholder">
        <button @click="triggerFileInput">
          <img src="@/assets/upload-icon.png" alt="Upload" />
        </button>

        <div class="audio-recorder-wrapper">
          <AudioRecorder @audio-recorded="handleAudioRecorded" />
        </div>
        <textarea v-model="newMessage" placeholder="Type a message..." @input="adjustTextareaHeight"></textarea>

        <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" />

        <button @click="sendMessage" :disabled="!newMessage.trim() && !file && !audioBlob">
          <img src="@/assets/images/SendMessage.svg" alt="Send Message" />
        </button>
      </div>
    </div>

    <div v-else class="chat-input locked">
      <div class="locked-message">{{ lockedMessage }}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
import ChatMessages from './ChatMessages.vue';
import AudioRecorder from './AudioRecorder.vue'; // Import the AudioRecorder component
import eventBus from '@/utils/eventBus.js';
import BackIcon from '@/assets/images/back-svgrepo-com.svg';

export default {
  components: { ChatMessages, AudioRecorder },
  props: { selectedConversation: Object },
  setup() {
    const router = useRouter();
    const navigateToCreatorFeed = (creatorName) => {
      router.push({ name: 'creatorFeed', params: { id: creatorName } });
    };
    return { navigateToCreatorFeed };
  },
  data() {
    return {
      messages: [],
      newMessage: '',
      file: null,
      filePreviewUrl: '',
      audioBlob: null, // For the recorded audio blob
      audioUrl: null,  // For the audio preview URL
      userId: null,
      BackIcon: BackIcon,
      creatorName: '',
      error: null,
      userRole: null,
      isSubscribed: false,
      isLoading: true,
    };
  },
  computed: {
    canSendMessage() {
      return !this.isLoading && this.isSubscribed;
    },
    lockedMessage() {
      return this.isCreator
          ? `${this.selectedConversation.other_user} is not subscribed to you`
          : `Subscribe to ${this.selectedConversation.other_user} to send messages`;
    },
    isCreator() {
      return this.userRole === 2;
    },
  },
  watch: {
    selectedConversation: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.id) {
          this.creatorName = newValue.other_user;
          this.checkSubscriptionStatus();
          this.markMessagesAsRead();
        } else {
          this.creatorName = '';
        }
      },
    },
  },
  async created() {
    await this.fetchUserProfile();
    this.checkSubscriptionStatus();
    this.setupMessageListener();
  },
  beforeUnmount() {
    eventBus.off('new-message', this.handleNewMessage);
  },
  methods: {
    async fetchUserProfile() {
      try {
        const response = await axios.get('/users/profile', {
          withCredentials: true,
        });
        this.userId = response.data.user_id;
        this.userRole = response.data.role;
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    },
    async checkSubscriptionStatus() {
      this.isLoading = true;
      try {
        let response;

        if (this.isCreator) {
          // If the user is a creator, check if the consumer is subscribed to them
          response = await axios.get(`/subscriptions/check/${this.selectedConversation.other_user}/to-creator`, {
            withCredentials: true,
          });
        } else {
          // If the user is a consumer, check if they are subscribed to the creator
          response = await axios.get(`/subscriptions/check/${this.selectedConversation.other_user}`, {
            withCredentials: true,
          });
        }

        this.isSubscribed = response.data.is_subscribed;

      } catch (error) {
        console.error('Error checking subscription status:', error);
      } finally {
        this.isLoading = false;
      }
    }
,
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];

      // Generate file preview URL
      if (this.file) {
        this.filePreviewUrl = URL.createObjectURL(this.file);
      }
    },
    removeAttachment() {
      this.file = null;
      this.filePreviewUrl = '';
      this.$refs.fileInput.value = ''; // Reset the file input
    },
    removeAudioRecording() {
      this.audioBlob = null;
      this.audioUrl = '';
    },
    handleAudioRecorded(audioBlob) {
      this.audioBlob = audioBlob;
      this.audioUrl = URL.createObjectURL(audioBlob);
    },
    isImage(file) {
      return file && file.type.startsWith('image/');
    },
    isVideo(file) {
      return file && file.type.startsWith('video/');
    },
    isAudio(file) {
      return file && file.type.startsWith('audio/');
    },
    async sendMessage() {
      if (!this.canSendMessage || (!this.newMessage.trim() && !this.file && !this.audioBlob)) return;

      const formData = new FormData();
      formData.append('conversation_id', this.selectedConversation.id);
      formData.append('content', this.newMessage);
      if (this.file) {
        formData.append('attachment', this.file);
      }
      if (this.audioBlob) {
        formData.append('attachment', this.audioBlob, 'audio.wav'); // Append the audio blob
      }

      try {
        const response = await axios.post('/messages', formData, {
          withCredentials: true,
        });

        const messageData = response.data;

        if (this.$refs.chatMessages) {
          this.$refs.chatMessages.updateMessages(messageData);
        }

        eventBus.emit('message-sent');

      } catch (error) {
        console.error('Error sending message:', error);
        eventBus.emit('message-send-error', error.message);
      } finally {
        this.newMessage = '';
        this.file = null;
        this.filePreviewUrl = '';
        this.audioBlob = null;
        this.audioUrl = '';
        if (this.$refs.fileInput) {
          this.$refs.fileInput.value = '';
        }
      }
    },

    backToOverview() {
      this.$emit('back-to-overview');
    },
    adjustTextareaHeight(event) {
      const textarea = event.target;
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
    },
    async markMessagesAsRead() {
      if (this.selectedConversation && this.selectedConversation.id) {
        try {
          await axios.post(`/conversations/${this.selectedConversation.id}/read`, null, {
            withCredentials: true,
          });
          if (this.$refs.chatMessages) {
            this.$refs.chatMessages.updateMessagesReadStatus();
          }
          eventBus.emit('messages-read', this.selectedConversation.id);
        } catch (error) {
          console.error('Error marking messages as read:', error);
        }
      }
    },
    setupMessageListener() {
      eventBus.on('new-message', this.handleNewMessage);
    },
    handleNewMessage(message) {
      if (message.conversation_id === this.selectedConversation.id) {
        this.markMessagesAsRead();
      }
    },
  },
};
</script>

<style scoped>
.NameOfMessage {
  padding-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #3d2946;
  text-align: center;
}

.chat-input.locked {
  background-color: rgba(61, 41, 70, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-top: 1px solid rgba(61, 41, 70, 0.4);
}

.locked-message {
  color: var(--chatinput-color);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  text-align: center;
}

.message-chat {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  overflow: hidden;
  max-width: 70%;
  min-width: 100%;
}

.chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-bottom: 1px solid rgba(61, 41, 70, 0.4);
  background-color: var(--bg-color);
  z-index: 1;
}

.BackButton {
  width: 0px;
  margin-right: 0px;
  cursor: pointer;
  float: left;
  height: 70px;
  transition: width 0.3s ease, border-radius 0.3s ease;
  overflow: hidden;
  position: relative;
  display: flex;
  filter: brightness(0) saturate(100%) invert(16%) sepia(5%) saturate(5050%) hue-rotate(237deg) brightness(97%) contrast(92%);
}

.creator-name {
  color: var(--topbar-h1-color);
  font-family: 'Rouge Script', cursive;
  font-size: 30px;
  font-weight: 200;
  text-decoration: none;
}

.chat-body {
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
}

.chatholder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  border: 1px solid rgba(61, 41, 70, 1);
  width: 100%;
  box-sizing: border-box;
}

.chat-input img {
  width: 20px;
  height: 20px;
  color: white;
}

.chat-input button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  filter: var(--chat-icons-color);
}

.chat-input button img {
  width: 20px;
  height: 20px;
  padding: 0px 10px;

}

.audio-recorder-wrapper img {
  width: 20px; /* Same size as your icons */
  height: 20px;
}

.chat-input textarea {
  flex-grow: 1;
  resize: none;
  min-height: 30px;
  max-height: 30px;
  border: 1px solid rgba(61, 41, 70, 0);
  border-radius: 20px;
  background-color: transparent;
  color: var(--chatinput-color);
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-left: 10px;
  padding: 3px 10px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 5px;
  border-top: 1px solid rgba(61, 41, 70, 0.4);
  background-color: var(--bg-color);
}

.chat-input button:hover {
  background-color: lightgrey;
  border-radius: 20px;
}

.media-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.media-preview-item {
  position: relative;
  margin-right: 10px;
}

.preview-image,
.preview-video {
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
}

.preview-audio{
  max-width: 300px;
  max-height: 100px;
  border-radius: 5px;
}

.remove-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-btn:hover {
  background-color: darkred;
}

@media (max-width: 1100px) {
  .message-chat {
    max-width: 100%;
    font-size: 16px;
  }


  .chat-header {
    position: sticky;
    top: 0;
  }

  .chat-input {
    position: sticky;
    bottom: 0;
    font-size: 16px;
  }

  .BackButton {
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
    float: left;
    height: 70px;
    transition: width 0.3s ease, border-radius 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
    filter: brightness(0) saturate(100%) invert(16%) sepia(5%) saturate(5050%) hue-rotate(237deg) brightness(97%) contrast(92%);
  }
}

@media (max-width: 768px) {
  .message-chat {
    max-width: 100%;
    font-size: 16px;
  }

  .chat-header {
    position: sticky;
    top: 0;
  }

  .chat-input {
    position: sticky;
    bottom: 0;
    font-size: 16px;
  }

  .BackButton {
    width: 40px;
    margin-right: 10px;
    cursor: pointer;
    float: left;
    height: 70px;
    transition: width 0.3s ease, border-radius 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
    filter: brightness(0) saturate(100%) invert(16%) sepia(5%) saturate(5050%) hue-rotate(237deg) brightness(97%) contrast(92%);
  }
}
</style>
