<template>
  <div class="message-overview">
    <input type="text" v-model="searchQuery" placeholder="Search..." class="search-bar"/>
    <ul>
      <li v-for="conversation in filteredConversations"
          :key="conversation.id"
          @click="selectConversation(conversation)"
          :class="{ active: conversation.id === selectedConversationId }">
        <div class="conversation-item">
          <img :src="getProfilePicture(conversation.profile_picture, conversation.role)" alt="Profile" class="profile-picture"/>
          <div class="conversation-details">
            <div class="username">{{ conversation.other_user }}</div>
            <div class="message-preview">{{ conversation.last_message_preview }}</div>
            <div class="message-date">{{ formatDate(conversation.last_message_at) }}</div>
          </div>
          <div v-if="conversation.unread_count > 0" class="unread-indicator">
            {{ conversation.unread_count }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import eventBus from '@/utils/eventBus.js';  // Adjust the import path accordingly

export default {
  data() {
    return {
      conversations: [],
      searchQuery: '',
      selectedConversationId: null,  // Store the ID of the selected conversation
    };
  },
  computed: {
    filteredConversations() {
      return this.conversations.filter(conversation =>
          conversation.other_user.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  async created() {
    await this.fetchConversations();

    // Check for query parameter to start a new conversation
    const userToMessage = this.$route.query.user;
    if (userToMessage) {
      this.startConversationWithUser(userToMessage);
    }

    // Listen for the message-sent event
    eventBus.on('message-sent', this.handleMessageSent);
    eventBus.on('messages-read', this.handleMessagesRead);
  },
  beforeUnmount() {
    eventBus.off('message-sent', this.handleMessageSent);
    eventBus.off('messages-read', this.handleMessagesRead);
  },
  methods: {
    async fetchConversations() {
      try {
        const response = await axios.get('/conversations', {
          withCredentials: true,
        });
        this.conversations = response.data.map(conversation => ({
          ...conversation,
          unread_count: conversation.unread_count || 0
        }));
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    },
    selectConversation(conversation) {
      this.selectedConversationId = conversation.id;  // Set the selected conversation ID
      this.$emit('select-conversation', conversation);
      // Mark messages as read when conversation is selected
      this.markMessagesAsRead(conversation.id);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    },
    async startConversationWithUser(username) {
      try {
        // Find the user by username
        const userResponse = await axios.get(`/users/${username}`, {
          withCredentials: true,
        });
        const userId = userResponse.data.id;

        // Check if conversation already exists
        const existingConversation = this.conversations.find(convo =>
            convo.other_user === username
        );

        if (existingConversation) {
          // Select the existing conversation
          this.selectConversation(existingConversation);
        } else {
          // Start a new conversation
          const newConversationResponse = await axios.post('/conversations', {
            user1_id: localStorage.getItem('user_id'),
            user2_id: userId,
          }, {
            withCredentials: true,
          });
          this.conversations.push(newConversationResponse.data);
          this.selectConversation(newConversationResponse.data);
        }
      } catch (error) {
        console.error('Error starting conversation:', error);
      }
    },
    getProfilePicture(profilePicture, role) {
      const creatorDefaultImage = require('@/assets/images/picreator.webp');
      const consumerDefaultImage = require('@/assets/images/piconsumer.webp');

      // If a profile picture is provided, append the access token as a query string
      if (profilePicture) {
        return `${profilePicture}`;
      } else {
        // Return default images based on the user's role
        return (role === 'creator') ? creatorDefaultImage : consumerDefaultImage;
      }
    },
    handleMessageSent() {
      this.fetchConversations();
    },
    handleMessagesRead(conversationId) {
      const conversation = this.conversations.find(c => c.id === conversationId);
      if (conversation) {
        conversation.unread_count = 0;
      }
    },
    async markMessagesAsRead(conversationId) {
      try {
        await axios.post(`/conversations/${conversationId}/read`, null, {
          withCredentials: true,
        });
        this.handleMessagesRead(conversationId);
      } catch (error) {
        console.error('Error marking messages as read:', error);
      }
    },
  }
};
</script>

<style scoped>
.message-overview {
  width: 300px;
  height: calc(100vh - 90px);
  border-right: 1px solid rgba(61, 41, 70, 0.4);
  overflow-y: auto;
  margin-left: 10%;

}

.search-bar {
  width: 86%;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid rgba(61, 41, 70, 1);
  background-color: rgba(0, 0, 0, 0);
  color: white;
  font-family: 'Roboto', sans-serif;
  border-radius: 50px;
}

.message-overview ul {
  list-style-type: none;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

.message-overview li {
  height: 90px;
  display: flex;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 2px;
  color: var(--message-overview-color);
  background-color: var(--message-overview-bg);
}

.message-overview li:hover {
  background-color: var(--message-overview-hover-bg);
}

.message-overview li.active {
  background-color: var(--message-overview-active-bg);
}

.conversation-item {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  image-rendering: optimizeSpeed;
}

.conversation-details {
  margin-right: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.username {
  font-family: 'Rouge Script', cursive;
  margin-right: auto;
  font-weight: 500;
  font-size: 25px;
}

.message-preview {
  margin-right: auto;
  color: var(--message-preview-color);
  font-weight: 300;
}

.message-date {
  margin-right: auto;
  font-size: 12px;
  color: var(--message-date-color);
  margin-top: 4px;
  font-weight: 300;
  margin-top: 30px;
}

.unread-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: purple;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

@media (max-width: 1100px) {
  .message-overview {

    border-right: none;
    margin-left: -0%;
  }
}

@media (max-width: 768px) {
  .message-overview {
    width: 100%;
    height: 100%;
    margin-left: 0;
    border-right: none;
  }

  .search-bar {
    width: calc(100% - 40px);
    margin: 20px auto;
  }
}
</style>