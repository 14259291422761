<template>
  <div class="messages-container" :style="{ height: containerHeight }">
    <transition name="fade" mode="out-in">
    <MessageOverview
        @select-conversation="selectConversation"
        :class="{ 'mobile-hidden': isMobile && selectedConversation }"
    />
    </transition>
    <div class="message-chat-wrapper" :class="{ 'mobile-full': isMobile }">
      <MessageChat
          v-if="selectedConversation"
          :selectedConversation="selectedConversation"
          @back-to-overview="backToOverview"
      />
    </div>
  </div>
</template>

<script>
import MessageOverview from './MessageOverview.vue';
import MessageChat from './MessageChat.vue';

export default {
  components: {
    MessageOverview,
    MessageChat
  },
  data() {
    return {
      selectedConversation: null,
      isMobile: false,
      vh: 0
    };
  },
  computed: {
    containerHeight() {
      return `calc(${100 * this.vh}px - 80px)`;
    }
  },
  methods: {
    selectConversation(conversation) {
      this.selectedConversation = conversation;
    },
    backToOverview() {
      if (this.isMobile) {
        this.selectedConversation = null;
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    setViewportHeight() {
      this.vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    },
    handleResize() {
      this.checkMobile();
      this.setViewportHeight();
    }
  },
  mounted() {
    this.checkMobile();
    this.setViewportHeight();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.messages-container {
  display: flex;
  height: calc(var(--vh, 1vh) * 100 - 80px); /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100 - 90px);
  overflow: hidden;
}

.message-chat-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@media (max-width: 768px) {
  .messages-container {
    flex-direction: column;
  }

  .mobile-hidden {
    display: none;
  }

  .mobile-full {
    flex: 1;
    width: 100%;
  }
}
</style>