<template>
  <div>
    <div class="formcontainer">
      <div v-if="alert" :class="['alert', { 'alert-success': alertType === 'success', 'alert-error': alertType === 'error' }]">
        {{ alertMessage }}
      </div>
      <div class="form-group">
        <form @submit.prevent="signup">
          <div class="input-wrapper">
            <input type="text" v-model="username" placeholder="Username" required />
            <div :class="['validation-indicator', getValidationClass(username, isUsernameValid)]"></div>
          </div>
          <div class="input-wrapper">
            <input type="email" v-model="email" placeholder="Email" required />
            <div :class="['validation-indicator', getValidationClass(email, isEmailValid)]"></div>
          </div>
          <div class="input-wrapper">
            <input type="password" v-model="password" placeholder="Password" required />
            <div :class="['validation-indicator', getValidationClass(password, isPasswordValid)]"></div>
          </div>
          <label>Role:</label>
          <div class="role-options">
            <label :class="{ selected: role === 'consumer' }">
              <input type="radio" value="consumer" v-model="role" />
              <span>Consumer</span>
            </label>
            <label :class="{ selected: role === 'creator' }">
              <input type="radio" value="creator" v-model="role" />
              <span>Creator</span>
            </label>
          </div>
          <div class="formbutton">
            <button type="submit" class="submit-button" :disabled="!isFormValid">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
    <div class="back-link">
      <router-link class="router-link" @click.prevent="$emit('toggle-form')" to="/">Back to Login</router-link>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'SignupPage',
  setup() {
    const axios = inject('axios');
    return { axios };
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      role: 'consumer',
      alert: false,
      alertMessage: '',
      alertType: ''
    };
  },
  computed: {
    isUsernameValid() {
      return this.username.length >= 8 && this.username.length <= 25;
    },
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isPasswordValid() {
      return this.password.length >= 8;
    },
    isFormValid() {
      return this.isUsernameValid && this.isEmailValid && this.isPasswordValid;
    }
  },
  methods: {
    async signup() {
      if (!this.isFormValid) {
        this.showAlert('Please fill all fields correctly', 'error');
        return;
      }

      try {
        await this.axios.post('/users/register', {
          username: this.username,
          email: this.email,
          password: this.password,
          role: this.role
        }, {
          withCredentials: true, // Send cookies for CSRF token
        });

        this.showAlert('Success signing up', 'success');
        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.error) {
          this.showAlert(error.response.data.error, 'error');
        } else {
          this.showAlert('Sign up failed', 'error');
        }
      }
    },
    showAlert(message, type) {
      this.alert = true;
      this.alertMessage = message;
      this.alertType = type;
      setTimeout(() => {
        this.alert = false;
      }, 5000);
    },
    getValidationClass(value, isValid) {
      if (value === '') return 'neutral';
      return isValid ? 'valid' : 'invalid';
    }
  }
};
</script>

<style scoped>
.form-group {
  margin-bottom: 15px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.formcontainer {
  height: 250px;
}

.router-link {
  width: 100%;
  text-decoration: none;
  color: rgba(180, 139, 187, 1);
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-size: 14px;
}

.formbutton {
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

label {
  display: block;
  margin-bottom: 5px;

  text-align: left;
  color: rgba(180, 139, 187, 1);
}

.input-wrapper {
  position: relative;
  margin-bottom: 15px;
}

input {
  width: 100%;
  background-color: rgba(217, 217, 217, 1);
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 50px;
  height: 40px;
  padding-right: 30px; /* Make room for the validation indicator */
}

.validation-indicator {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.validation-indicator.neutral {
  background-color: #ccc;
}

.validation-indicator.valid {
  background-color: #28a745;
}

.validation-indicator.invalid {
  background-color: #dc3545;
}

.submit-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 27px;
  font-size: 16px;
  color: #fff;
  background-color: rgba(97, 61, 105, 0.5);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 100%;
  height: 40px;
}

.submit-button:hover:not(:disabled) {
  background-color: rgba(142, 100, 149, 1);
}

.submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.role-selection {
  margin-bottom: 20px;
  text-align: center;
}

.role-options {
  display: flex;
  gap: 10px;
  width: 100%;
}

.role-options label {
  width: 90px;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border: 1px solid rgba(142, 100, 149, 1);
  border-radius: 50px;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  font-size: 14px;
  text-rendering: optimizeLegibility;
}

.role-options input {
  display: none;
  text-align: center;
}

.role-options span {
  margin-left: 0px;
  text-align: center;
}

.role-options .selected {
  background-color: rgba(97, 61, 105, 0.5);
  color: white;
}

.back-link {
  margin-top: 50px;
}

.alert {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  width: 80%; /* Adjust as needed */
  max-width: 300px; /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@media screen and (max-width: 768px) {
  .form-group {
    margin-bottom: 15px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }

  .formcontainer {
    height: 250px;
  }

  .router-link {
    width: 100%;
    text-decoration: none;
    color: rgba(180, 139, 187, 1);
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    font-size: 14px;
  }

  .formbutton {
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  label {
    display: block;
    margin-bottom: 5px;
    text-align: left;
    color: rgba(180, 139, 187, 1);
  }

  input {
    width: 100%;
    background-color: rgba(217, 217, 217, 1);
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 50px;
    height: 40px;

  }

  .submit-button {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 27px;
    font-size: 16px;
    color: #fff;
    background-color: rgba(97, 61, 105, 0.5);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    width: 100%;
    height: 40px;
  }

  .submit-button:hover:not(:disabled) {
    background-color: rgba(142, 100, 149, 1);
  }

  .role-selection {
    margin-bottom: 20px;
    text-align: center;
  }

  .role-options {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  .role-options label {
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    border: 1px solid rgba(142, 100, 149, 1);
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    font-size: 14px;
    text-rendering: optimizeLegibility;
  }

  .role-options input {
    display: none;
    text-align: center;
  }

  .role-options span {
    margin-left: 0px;
    text-align: center;
  }

  .role-options .selected {
    background-color: rgba(97, 61, 105, 0.5);
    color: white;
  }

  .back-link {
    margin-top: 50px;
  }
}
</style>