<!-- src/components/ChatMessages.vue -->

<template>
  <div class="chat-messages" ref="chatContainer" @scroll="handleScroll">
    <div v-for="message in visibleMessages" :key="message.id"
         :class="['message', message.sender_id == userId ? 'my-message' : 'their-message', {'unread': !message.is_read}]">
      <div class="message-content">
        {{ message.content }}
        <div v-if="message.attachment_url" class="attachment">
          <img v-if="isImage(message.attachment_url)" :src="getAuthenticatedUrl(message.attachment_url)" alt="Image Attachment" class="attachment-image"/>
          <video v-if="isVideo(message.attachment_url)" controls class="attachment-video">
            <source :src="getAuthenticatedUrl(message.attachment_url)" type="video/mp4">
            Your browser does not support the video tag.
          </video>
          <audio v-if="isAudio(message.attachment_url)" controls class="attachment-audio">
            <source :src="getAuthenticatedUrl(message.attachment_url)" type="audio/mpeg">
            Your browser does not support the audio tag.
          </audio>
          <a v-if="!isImage(message.attachment_url) && !isVideo(message.attachment_url) && !isAudio(message.attachment_url)"
             :href="getAuthenticatedUrl(message.attachment_url)" target="_blank">Download Attachment</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    conversationId: Number,
    userId: Number,
  },
  data() {
    return {
      visibleMessages: [],
      allMessages: [],
      messageBatchSize: 20,
      isLoading: false,
      reachedEnd: false,
      previousScrollHeight: 0,
      accessToken: localStorage.getItem('access_token')
    };
  },
  watch: {
    conversationId: {
      immediate: true,
      handler(newConversationId) {
        if (newConversationId) {
          this.reachedEnd = false;
          this.loadMessages(true);
        } else {
          this.visibleMessages = [];
          this.allMessages = [];
        }
      }
    }
  },
  methods: {
    async loadMessages(initial = false) {
      if (this.isLoading || this.reachedEnd || !this.conversationId) return;

      this.isLoading = true;
      const offset = initial ? 0 : this.allMessages.length;

      try {
        const response = await axios.get(`/conversations/${this.conversationId}/messages`, {
          params: {offset, limit: this.messageBatchSize},
          withCredentials: true,
        });

        const newMessages = response.data.reverse();
        if (newMessages.length < this.messageBatchSize) {
          this.reachedEnd = true;
        }

        if (initial) {
          this.allMessages = newMessages;
          this.visibleMessages = newMessages;
        } else {
          this.allMessages = [...newMessages, ...this.allMessages];
          this.visibleMessages = this.allMessages;
        }

        this.$nextTick(() => {
          if (initial) {
            this.scrollToBottom();
          } else {
            const chatContainer = this.$refs.chatContainer;
            if (chatContainer) {
              chatContainer.scrollTop = chatContainer.scrollHeight - this.previousScrollHeight;
            }
          }
        });
      } catch (error) {
        console.error('Error fetching messages:', error);
      } finally {
        this.isLoading = false;
      }
    },
    handleScroll() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer && chatContainer.scrollTop === 0 && !this.isLoading) {
        this.previousScrollHeight = chatContainer.scrollHeight;
        this.loadMessages();
      }
    },
    scrollToBottom() {
      const chatContainer = this.$refs.chatContainer;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    },
    updateMessages(newMessage) {
      if (newMessage.attachmentUrl) {
        // Convert attachmentUrl to attachment_url
        newMessage.attachment_url = newMessage.attachmentUrl;
        delete newMessage.attachmentUrl;
      }

      this.allMessages.push(newMessage);
      this.visibleMessages = this.allMessages.slice(-this.messageBatchSize);
      this.$nextTick(() => this.scrollToBottom());
    },
    updateMessagesReadStatus() {
      this.visibleMessages.forEach(message => {
        if (message.sender_id !== this.userId) {
          message.is_read = true;
        }
      });
    },
    async markMessagesAsRead() {
      if (this.conversationId) {
        try {
          await axios.post(`/conversations/${this.conversationId}/read`, null, {
            withCredentials: true,
          });
          this.updateMessagesReadStatus();
        } catch (error) {
          console.error('Error marking messages as read:', error);
        }
      }
    },
    getAuthenticatedUrl(filename) {
      if (!filename) return '';

      const conversationId = this.conversationId;  // Use the conversationId prop

      // Check if the filename is already a full URL
      if (filename.startsWith('http')) {
        return `${filename}${filename.includes('?') ? '&' : '?'}conversationId=${conversationId}`;
      }

      // Append conversationId as a query parameter
      return `${axios.defaults.baseURL}/uploads/conversation/${filename}?conversationId=${conversationId}`;
    },
    isImage(url) {
      return /\.(jpg|JPG|webp|jpeg|png|gif)$/.test(url);
    },
    isVideo(url) {
      return /\.(mp4|mov|avi|mkv|webm)$/.test(url);
    },
    isAudio(url) {
      return /\.(mp3|wav|m4a|ogg)$/.test(url);
    },
  },
  mounted() {
    this.markMessagesAsRead();
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500&display=swap');

.chat-messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  height: 100%;
}

.message {
  min-width: 60%;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 15px;
  max-width: 70%;
  word-wrap: break-word;
  font-size: 0.8rem;
}

.my-message {
  text-align: right;
  font-family: 'Roboto', sans-serif;
  align-self: flex-end;
  background-color: rgba(61, 41, 70, 1);
  color: white;
  border-bottom-right-radius: 0;
}

.attachment {
  margin-top: 10px;
}

.attachment-image {
  max-width: 200px;
  border-radius: 5px;
  max-height: 300px;
}

.attachment-video, .attachment-audio {
  width: 200px;
  margin-top: 5px;
}

.their-message {
  font-family: 'Roboto', sans-serif;
  text-align: left;
  align-self: flex-start;
  color: white;
  background-color: rgba(142, 100, 149, 1);
  border-bottom-left-radius: 0;
}

.unread {
  font-weight: bold;
}
</style>