// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import { createPinia } from 'pinia';

// Create axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: true,
});

const pinia = createPinia();

const app = createApp(App);

// Provide axios to all components
app.provide('axios', axiosInstance);
app.config.globalProperties.$http = axiosInstance;

app.use(router);
app.use(pinia);
app.mount('#app');

// Function to get CSRF token from cookies
function getCsrfToken() {
  const csrfCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('XSRF-TOKEN='));
  return csrfCookie ? csrfCookie.split('=')[1] : null;
}

// Axios request interceptor (CSRF protection + sending cookies)
axiosInstance.interceptors.request.use(function (config) {
  const csrfToken = getCsrfToken();
  if (csrfToken) {
    config.headers['X-XSRF-TOKEN'] = csrfToken;  // Include CSRF token in the request header
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// Axios response interceptor (handling 401 and token refresh)
axiosInstance.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;  // Prevent infinite retry loop

    try {
      // Attempt to refresh the token
      await axios.post(`${process.env.VUE_APP_BASE_URL}/refresh-token`, {}, {
        withCredentials: true,  // Send cookies for refresh token
      });

      // Retry the original request
      return axiosInstance(originalRequest);
    } catch (refreshError) {
      console.error('Failed to refresh token on 401 error', refreshError);

      // Clear cookies and redirect to login
      document.cookie = "access_token=; Max-Age=0; path=/; secure; HttpOnly;";
      document.cookie = "refresh_token=; Max-Age=0; path=/; secure; HttpOnly;";
      await router.push('/login');

      return Promise.reject(refreshError);
    }
  }

  return Promise.reject(error);
});

export { axiosInstance };
