<template>
  <div class="topbar">
    <NavbarPhone class="mobile-nav" />

    <!-- Header with dynamic opacity based on isSearchExpanded -->
    <h1
        class="centered-header"
        :class="{ faded: isSearchExpanded }"
    >{{ headerText }}</h1>

    <div class="search-container" ref="searchContainer">
      <div class="search-bar" :class="{ expanded: isSearchExpanded }">
        <!-- Search icon (image) -->
        <img
            v-if="!isSearchExpanded"
            :src="`${require('@/assets/images/searchicon.png')}?v=1.0`"
            alt="Search Icon"
            class="search-icon"
        />
        <input
            type="text"
            id="search-field"
            v-model="searchQuery"
            name="searchQuery"
            @focus="expandSearch"
            @blur="collapseSearch"
            @input="searchCreators"
            class="search-input"
        />
        <button label="Name" @click="clearSearch" class="search-button" v-if="searchQuery">
          <i class="fas fa-times"></i>
        </button>

      </div>
      <ul v-if="searchQuery" class="dropdown">
        <li v-if="creators.length === 0" class="no-results">
          No creators found
        </li>
        <li v-for="creator in creators" :key="creator.id" @click="goToCreator(creator.username)">
          <img :src="getProfileImageWithToken(creator.profileImage)" alt="Profile" class="creator-profile-image" />
          {{ creator.username }}
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import NavbarPhone from '@/components/containers/NavbarPhone.vue';
import axios from 'axios';
import { debounce } from 'lodash';




export default {
  name: "TopBar",
  components: {
    NavbarPhone
  },
  data() {
    return {
      headerText: "",
      searchQuery: '',
      creators: [],
      isSearchExpanded: false // Track if the search is expanded
    };
  },
  watch: {
    $route(to) {
      this.updateHeaderText(to);
    }
  },
  created() {
    this.updateHeaderText(this.$route);
    this.debouncedSearch = debounce(this.performSearch, 300);
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  methods: {
    expandSearch() {
      this.isSearchExpanded = true;
    },
    collapseSearch() {
      if (!this.searchQuery) {
        this.isSearchExpanded = false;
      }
    },
    updateHeaderText(route) {
      const path = route.path;
      if (path === "/feed") {
        this.headerText = "Feed";
      } else if (path === "/messages") {
        this.headerText = "My Messages";
      } else if (path === "/settings") {
        this.headerText = "My Settings";
      } else if (path === "/subscriptions") {
        this.headerText = "My Subscriptions";
      } else {
        const username = path.split('/')[1];
        this.headerText = username || "Creator";
      }
    },
    clearSearch() {
      this.searchQuery = '';
      this.creators = [];
    },
    searchCreators() {
      if (this.searchQuery.length > 0) {
        this.debouncedSearch();
      } else {
        this.creators = [];
      }
    },
    async performSearch() {
      try {
        const response = await axios.get(`/search/creators?query=${this.searchQuery}`, {
          withCredentials: true,
        });
        this.creators = response.data;
      } catch (error) {
        console.error('Error searching creators:', error);
      }
    },
    goToCreator(username) {
      this.$router.push(`/${username}`);
      this.clearSearch();
    },
    getProfileImageWithToken(profileImage) {

      if (profileImage) {
        return `${profileImage}`;
      } else {
        return require('@/assets/images/picreator.webp'); // Use require to correctly resolve the image path
      }
    },
    handleOutsideClick(event) {
      const searchContainer = this.$refs.searchContainer;
      if (searchContainer && !searchContainer.contains(event.target)) {
        this.clearSearch();
      }
    }
  }
};
</script>



<style scoped>

.topbar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0px;
  height: 90px;
  background-color: var(--topbar-background);
  z-index: 9000;
}

.navbar-phone {
  position: absolute;



}

.centered-header {
  position: absolute;
  font-size: 2.3rem;
  color: var(--topbar-h1-color);
  font-family: 'Rouge Script', cursive;
  font-weight: 100;
  text-align: center;
  margin: 0;
  transition: opacity 0.3s ease;
}

/* Faded class to hide the header when the search bar is expanded */
.centered-header.faded {
  opacity: 0;
}

.search-container {
  position: absolute;
  right: 200px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

.search-bar {
  display: flex;
  align-items: center;
  background: #ebebeb;
  color: #3d2946;
  border-radius: 25px;
  width: 40px; /* Default circle size */
  height: 40px;
  transition: width 0.3s ease, border-radius 0.3s ease;
  overflow: hidden;
  position: relative;
}

.search-bar.expanded {
  width: 200px;
  border-radius: 25px;
  color: #3d2946;
}

.search-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
}

.search-bar.expanded .search-icon {
  opacity: 0; /* Fade out when expanded */
}

.search-input {
  flex-grow: 1;
  background: none;
  border: none;
  color: var(--topbar-h1-color);
  padding: 5px;
  font-size: 20px;
  width: 100%;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.search-bar.expanded .search-input {
  opacity: 1; /* Make input visible when expanded */
}

.search-input:focus, input:focus {
  outline: none;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
}

.search-button {
  background: none;
  border: none;
  color: var(--topbar-h1-color);
  cursor: pointer;
  font-size: 18px;
  padding: 5px;
}
.mobile-nav {
  display: none;
}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--search-background);
  border-radius: 4px;
  border: 1px solid rebeccapurple;
  z-index: 99000;
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown li:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.creator-profile-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

@media screen and (max-width: 1100px) {
  .topbar {
    justify-content: space-between;
    height: 90px;
    background-color: var(--topbar-background);
  }
  .centered-header {
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.1rem;
    color: var(--topbar-h1-color);
  }
  .mobile-nav {
    float: left;
    display: block;
    top: 50%;
    height: 70px;
    transition: width 0.3s ease, border-radius 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
  }
  .search-container {
    right: 20px;
    max-width: 200px;
    top: 50%;
    transform: translateY(-50%);
    color: #3d2946;
  }

}

@media screen and (max-width: 768px) {
  .topbar {
    justify-content: space-between;
    height: 90px;
    background-color: var(--topbar-background);
  }
  .centered-header {
    left: 50%;
    transform: translateX(-50%);
    font-size: 2.1rem;
    color: var(--topbar-h1-color);
  }
  .mobile-nav {
    float: left;
    display: block;
    top: 50%;
    height: 70px;
    transition: width 0.3s ease, border-radius 0.3s ease;
    overflow: hidden;
    position: relative;
    display: flex;
  }
  .search-container {
    right: 20px;
    max-width: 200px;
    top: 50%;
    transform: translateY(-50%);
    color: #3d2946;
  }
}

</style>